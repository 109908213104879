<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>       
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Genre </strong> <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="title">Title <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="genre.GenreName" id="id" placeholder="Genre"></b-form-input>
              </b-form-group>
            </b-col>
           
            <b-col sm="6">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="genre.GenreIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />                
              </b-form-group>
            </b-col>
          </b-row>
         
        
          <b-row>
              <b-col sm="6">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> {{ commontext.submitButton }}</b-button>
              </b-col>

              <b-col sm="6">
                  <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

        <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Genres
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
           <div slot="GenreIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.GenreIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.GenreID)"/>
          </div>
           <div slot="GenreID" slot-scope="props">
            <i class="fa fa-edit" @click="editGenre(props.row.GenreID)"></i>
          </div>          
        </v-client-table>
      </b-card-body>
    </b-card>

      </b-col>
    </b-row>
      <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>    
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";
export default {
  name: "Genre",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      genre: {
        Flag: 1,
        GenreID: null,
        GenreName: "",
        GenreIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["GenreName", "GenreIsActive", "GenreID"],
      data: [],
      options: {
        headings: {
          name: "Genre Name",
          GenreID: "Edit"
        },
        sortable: ["GenreName"],
        filterable: ["GenreName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getGenres();
  },
  methods: {
    resetForm: function() {
      this.genre = {
        GenreID: null,
        Flag: 1,
        GenreName: "",
        GenreIsActive: true
      };
      this.commontext.submitButton = "Submit";
    },
    getGenres: function() {
      // let res = await MasterService.getGenres();
      // this.data = res.data;
      MasterService.getGenres()
        .then(response => {
          const { data } = response;
          if (data.Status && data.GenreList.length > 0) {
            this.data = data.GenreList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.genre.GenreName == "") {
        this.showMessageSnackbar("Genre Name Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      // let res = await MasterService.addGenre(this.genre);
      this.closeModal("ModalConfirmationOnSubmit");

      MasterService.addGenre(this.genre)
        .then(response => {
          const { data } = response;
          this.getGenres();
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editGenre: function(GenreID) {
      let payload = {
        GenreID
      };
      // let res = await MasterService.getGenreByID(payload);
      // this.genre = res.data[0];
      MasterService.getGenreByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.GenreList.length > 0) {
            this.genre = data.GenreList[0];
            this.commontext.submitButton = "Update Genre";

            this.genre.Flag = 2;
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "GenreMaster");

      let payload = {
        TableName: "GenreMaster",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
